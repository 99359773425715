<template>
  <div>
    <!-- 顶部title开始 -->
    <div class="widthCommit">
      <p class="reportTitli">车型报价</p>
    </div>
    <!-- 顶部title结束 -->

    <!-- 车型报价modelQuotation开始 -->
    <div class="modelQuotation widthCommit">
      <!-- 搜索区域开始 -->
      <el-form :inline="true" :model="getDate" class="demo-form-inline">
        <el-form-item label="车辆型号">
          <el-input class="inp" v-model="getDate.model" placeholder="请输入准确车辆型号"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" class="botton" plain @click="query(0)">查询</el-button>
        </el-form-item>
      </el-form>
      <!-- 搜索区域结束 -->
      <!-- form表单开始 -->
      <el-form :model="getDate" class="demo-form-inline">
        <el-form-item class="functionDiv" label="车辆品牌">
          <span style="position: absolute; left: -8px; color: red">*</span>
          <el-select collapse-tags v-model="getDate.brand" placeholder="请选择">
            <div class="sctionDiv">
              <div id="sctionDivTit" class="sctionDivTit">
                <p
                  v-for="item in options"
                  @click="oooo(item.initials)"
                  :key="item.initials"
                >{{ item.initials }}</p>
              </div>
              <div class="sctionTow">
                <el-option-group
                  v-for="(group, index) in options"
                  :key="index"
                  :label="group.initials"
                  :id="group.initials"
                >
                  <el-option
                    style="text-align: center; font-size: 8px; color: #666666"
                    v-for="item in group.brands"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  ></el-option>
                </el-option-group>
              </div>
            </div>
          </el-select>
        </el-form-item>
        <el-form-item class="functionDiv" label="功能类型">
          <template>
            <div
              class="functionType"
              :class="{ active: isActive === type }"
              @click="addCategory(type)"
              v-for="(item, type) in list"
              :key="type"
            >{{ item.type }}</div>
          </template>
        </el-form-item>
        <el-form-item v-show="showActiveType == true" class="functionDiv" label="车辆类型">
          <template>
            <div
              class="functionType"
              @click="addType(type)"
              :class="{ active: isActiveType === type }"
              v-for="(item, type) in b"
              :key="type"
            >{{ item.type }}</div>
          </template>
        </el-form-item>
        <div v-show="!showActiveType" style="width: 100%; height: 50px"></div>
      </el-form>
      <!-- form表单结束 -->
      <!-- 按钮开始 -->
      <div class="functionBtn">
        <div>
          <el-button @click="resetButton" class="funBotton" type="info">重置</el-button>
          <el-button @click="query(1)" class="funBotton funBottonOne" type="primary">查询</el-button>
        </div>
      </div>
      <!-- 按钮结束 -->
    </div>
    <!-- 车型报价modelQuotation结束 -->
    <!-- 车辆信息型号infoModel开始 -->
    <div v-show="infoModelShow" class="infoModel widthCommit">
      <div class="infoModelTitle">
        <el-popover
          v-for="(item, index) in listCar"
          :key="index"
          placement="bottom"
          width="900"
          trigger="click"
          ref="aa"
        >
          <!-- popover-{{ index }} -->
          <div class="infoModelMianList">
            <div
              v-for="(ite, ind) in item.list"
              :key="ind"
              style="cursor: pointer"
              class="infoModelMianListOne"
              @click="aaa(ite, item.name, index)"
            >{{ ite }}</div>
          </div>
          <div
            :class="[mouseenterActive == index ? 'mouseenterActiveClass' : '']"
            @mouseenter="mouseenter(index)"
            @mouseout="mouseout(index)"
            slot="reference"
            class="infoModelTextTitle"
          >{{ item.title }}</div>
        </el-popover>

        <div style="right: 20px; position: absolute">
          <el-button
            @click="infoReset"
            class="funBotton funBottonOne funBottonOneOne"
            size="mini"
            plain
            type="primary"
          >重置</el-button>
        </div>
      </div>

      <div
        v-show="divActive"
        style="box-sizing: border-box; padding: 16px 20px 0px 0; display: flex"
      >
        <el-tag
          style="margin-left: 20px"
          effect="plain"
          v-show="item != ''"
          v-for="(item, index) in listBottom"
          closable
          :key="index"
          @close="handleClose(index)"
        >{{ item }}</el-tag>
      </div>
      <!-- 按钮结束 -->
    </div>
    <!-- 车辆信息型号infoModel结束 -->
    <!-- 车辆配置信息configurationInfo开始 -->
    <div class="configurationInfo widthCommit">
      <el-table
        :header-cell-style="{
          background: '#BFDFF7',
          color: '#333333',
          fontSize: '18px',
          fontWeight: 'normal',
        }"
        :data="tableData"
        style="width: 100%"
      >
        <!-- 图片 -->
        <el-table-column label="图片" width="100">
          <template slot-scope="scope">
            <div class="imagee">
              <img class="img" :src="scope.row.imageUrl" @click="imageDeati(scope.row)" alt />
              <div class="classText">
                <!-- <span class="spanText" v-if="scope.row.imageCount==''">{{scope.row.imageCount}}</span> -->
                <div class="spanText">
                  <span
                    class="spanText"
                    v-if="!scope.row.imageCount == ''"
                  >{{ scope.row.imageCount }}张</span>
                </div>
                <span class="spanText">上传图片</span>
              </div>
              <!-- <img class="img"  :src="scope.row.imageUrl" @click="imageDeati(scope.row)" alt=""> -->
              <!-- {{scope.row.imageUrl}} -->
            </div>
          </template>
        </el-table-column>

        <el-table-column label="车辆配置信息" width="450">
          <template slot-scope="scope">
            <span class="tableTitle">
              <!-- {{ scope.row }} -->
              {{ scope.row.brand }}
              {{ scope.row.type }}
              {{ scope.row.name }}
              <span
                v-if="scope.row.horsepower"
              >{{ scope.row.horsepower }}马力&nbsp;</span>
              <span
                v-if="scope.row.outerLength"
              >{{ (scope.row.outerLength / 1000).toFixed(2) }}米&nbsp;</span>
              {{ scope.row.fuelType }}
              {{ scope.row.emissionStandard }}
            </span>
            <div class="tableNewMian">
              <div class="tableNewMianOne">
                <div>车辆型号：{{ scope.row.model }}</div>
                <div>
                  发动机型号：{{ scope.row.engineBrand }}
                  {{ scope.row.engineModel }}
                </div>
              </div>
              <div class="tableNewMianOne" style="width: 86px">
                <div>驱动：{{ scope.row.driveType }}</div>
                <div>车长：{{ scope.row.outerLength }}</div>
              </div>
              <div class="tableNewMianOne" style="width: 86px">
                <div>速比：{{ scope.row.speedRatio }}</div>
                <div>厢长：{{ scope.row.cargoBoxLength }}</div>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" label="参考价">
          <template slot-scope="scope">
            <div v-show="scope.row.guidePrice" class="referencePrice">
              <span class="referencePriceMian">{{ scope.row.guidePrice }}万元</span>
              <div class="referencePriceMianTitle">厂商指导价:</div>
            </div>
            <div v-show="!scope.row.guidePrice" class="referencePrice">
              <span class="referencePriceMian">暂无</span>
              <div class="referencePriceMianTitle">厂商指导价:</div>
            </div>
            <div v-show="scope.row.recentPrice" class="referencePrice">
              <div class="referencePriceMian">{{ scope.row.recentPrice }}万元</div>
              <div class="referencePriceMianTitle referencePriceMianTitleOne">近期成交价:</div>
            </div>
            <div v-show="!scope.row.recentPrice" class="referencePrice">
              <div class="referencePriceMian">暂无</div>
              <div class="referencePriceMianTitle referencePriceMianTitleOne">近期成交价:</div>
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" label="最近报价" width="100">
          <template slot-scope="scope">
            <!-- <el-button type="text" @click="offer(scope.row)">报价</el-button> -->
            <p
              v-show="scope.row.guidePriceOffer"
              class="offerText"
            >{{ scope.row.guidePriceOffer }}万元</p>
            <p
              v-show="scope.row.recentPriceOffer && !scope.row.guidePriceOffer"
              class="offerText"
            >暂无</p>
            <!-- <p v-show="!scope.row.guidePriceOffer" class="offerText">万元</p> -->
            <p
              v-show="scope.row.recentPriceOffer"
              class="offerText"
            >{{ scope.row.recentPriceOffer }}万元</p>
            <p
              v-show="!scope.row.recentPriceOffer && scope.row.guidePriceOffer"
              class="offerText"
            >暂无</p>
          </template>
        </el-table-column>
        <el-table-column align="center" label="操作" width="100">
          <template slot-scope="scope">
            <el-button type="text" @click="offer(scope.row)">报价</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="pages.pageNumber"
        :page-sizes="[5, 10, 20, 50]"
        :page-size="pages.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      ></el-pagination>
    </div>
    <!-- 车辆配置信息configurationInfo结束 -->
    <!-- 提示框开始 -->
    <el-dialog title="车辆报价" :visible.sync="dialogFormVisible">
      <el-form class="form" label-position="right" label-width="122px" :model="QuotationList">
        <el-form-item label="地区" class="formDivA">
          <!-- <el-input
            class="inputLi"
            readonly
            v-model="QuotationItem.area"
          ></el-input>-->
          <el-select class="inputLi" v-model="QuotationItem.area" placeholder="请选择">
            <el-option
              v-for="item in msgOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="厂商指导价(万元)" class="formDivA">
          <el-input class="inputLi" v-model="QuotationList.guidePriceOffer"></el-input>
        </el-form-item>
        <el-form-item label="近期成交价(万元)" class="formDivA">
          <el-input class="inputLi" v-model="QuotationList.recentPriceOffer"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="quotationConfirmation">确 定</el-button>
      </div>
    </el-dialog>
    <!-- 提示框结束 -->
  </div>
</template>

<script>
export default {
  // 组件名称
  name: "demo",
  // 组件参数 接收来自父组件的数据
  props: {},
  // 局部注册的组件
  components: {},
  // 组件状态值
  data() {
    return {
      activeImg:
        "https://vehicle-library.oss-cn-beijing.aliyuncs.com/cvbd-icon/staff_vehicle_offer_background_20210701.png", //渲染大图的
      indexListOne: null, //判断是 上面的搜索还是下面的搜索
      divActive: false,
      listCar: [
        { title: "排放标准", list: [], name: "emissionStandard" },
        { title: "发动机品牌", list: [], name: "engineBrand" },
        { title: "发动机型号", list: [], name: "engineModel" },
        { title: "马力(Ps)", list: [], name: "horsepower" },
        { title: "货箱长(mm)", list: [], name: "cargoBoxLength" },
        { title: "车外廓长(mm)", list: [], name: "outerLength" },
        { title: "驱动形式", list: [], name: "driveType" },
        { title: "速比", list: [], name: "speedRatio" },
      ],

      mouseenterActive: null, //控制高亮
      given: true,
      options: [], //顶部下拉框的数据
      dialogFormVisible: false, //点击报价的显示隐藏
      value: "",
      list: [], //上面全部的数据
      tableData: [], //下面列表的数据
      // 功能类型/车辆类型 高亮展示的数据
      showActiveType: false, //车辆类型显示，消失
      isActive: null,
      isActiveType: null,

      a: [], //所有的数据
      b: [], //单一选中之后的数据
      // 请求数据需要的数据
      getDate: {
        model: "", //车辆型号
        brand: "", //品牌
        category: "", //功能类型
        type: "", //车型类型
      },
      infoModelShow: false, // 车辆信息型号infoModel 显示隐藏
      //获取下面的表格数据
      listBottom: {
        driveType: "", //驱动形式,
        engineBrand: "", //发动机品牌,
        engineModel: "", //发动机型号,
        horsepower: "", //马力
        outerLength: "", //车外廓长
        cargoBoxLength: "", //货箱长
        speedRatio: "", //速比1
        emissionStandard: "", //国五 国六
      },
      // 最后显示的
      showDriveTypes: true, //驱动形式
      showEngineBrandList: true, //发动机
      showOverallLengthList: true, //车外廓长
      showSpeedRatioList: true, //速比
      showContainerLengthList: true, //货箱长
      showEmissionStandards: true, //国五国六

      // 点击报价，带到当前行的数据QuotationItem
      QuotationItem: {},
      QuotationList: {
        guidePriceOffer: "", //
        recentPriceOffer: "",
        area: "",
        admCode: "",
        guidePrice: "",
        recentPrice: "",
        vehicleModelId: "",
      },
      msgOptions: [],
      total: null, //总页数
      pages: {
        pageSize: 10,
        pageNumber: 1,
      },
    };
  },
  // 计算属性
  computed: {},
  // 侦听器
  watch: {},
  // 组件方法
  methods: {
    // 图片跳转
    imageDeati(data) {
      console.log(data);
      // this.$router.push("/pictureImage")
      this.$router.push({
        path: "/pictureImage",
        query: { id: data },
      });
    },
    // 选择下标 功能类型
    addCategory(index) {
      this.isActive = index;
      this.b = [];
      if (this.a[index].types.length == 0) {
        // 如果没有车辆类型，则车辆类型消失
        this.showActiveType = false;
      } else {
        this.showActiveType = true;
      }
      this.a[index].types.forEach((item) => {
        this.b.push({
          type: item,
        });
      });
      this.getDate.type = "";
      this.getDate.category = this.a[index].category;
      this.isActiveType = null;
    },
    //选择下标  车辆类型
    addType(index) {
      this.isActiveType = index;
      console.log(this.b[index].type);
      this.getDate.type = this.b[index].type;
    },

    // 点击下面的重置
    infoReset() {
      this.listCar = [
        { title: "排放标准", list: [], name: "emissionStandard" },
        { title: "发动机品牌", list: [], name: "engineBrand" },
        { title: "发动机型号", list: [], name: "engineModel" },
        { title: "马力(Ps)", list: [], name: "horsepower" },
        { title: "货箱长(mm)", list: [], name: "cargoBoxLength" },
        { title: "车外廓长(mm)", list: [], name: "outerLength" },
        { title: "驱动形式", list: [], name: "driveType" },
        { title: "速比", list: [], name: "speedRatio" },
      ];
      this.given = true;
      this.query(this.indexListOne);
    },
    // 点击上面的查询按钮
    async query(index) {
      this.pages = {
        pageSize: 10,
        pageNumber: 1,
      };
      this.indexListOne = index;
      if (index == 0) {
        this.getDate.brand = "";
        this.getDate.category = "";
        this.getDate.type = "";
        console.log(this.getDate.brand);
        if (this.getDate.model == "") return this.$message.error("输入为空");
        var a = {
          model: this.getDate.model,
        };
      } else {
        console.log(this.getDate.brand);
        if (this.getDate.brand == "") return this.$message.error("输入为空");
        this.getDate.model = "";
        var a = {
          brand: this.getDate.brand,
          category: this.getDate.category,
          type: this.getDate.type,
        };
      }
      this.listBottom = {
        driveType: "", //驱动形式,
        engineBrand: "", //发动机品牌,
        engineModel: "", //发动机型号,
        horsepower: "", //马力
        outerLength: "", //车外廓长
        cargoBoxLength: "", //货箱长
        speedRatio: "", //速比1
        emissionStandard: "", //国五 国六
      };
      this.listCar = [
        { title: "排放标准", list: [], name: "emissionStandard" },
        { title: "发动机品牌", list: [], name: "engineBrand" },
        { title: "发动机型号", list: [], name: "engineModel" },
        { title: "马力(Ps)", list: [], name: "horsepower" },
        { title: "货箱长(mm)", list: [], name: "cargoBoxLength" },
        { title: "车外廓长(mm)", list: [], name: "outerLength" },
        { title: "驱动形式", list: [], name: "driveType" },
        { title: "速比", list: [], name: "speedRatio" },
      ];
      this.divActive = false;
      this.given = true;
      const { data: res } = await this.$http({
        method: "post",
        url: `/api/vehicleOffer/getVehicleFilter`,
        data: a,
      });
      console.log(this.a);
      if (res.content.emissionStandards.length != 0) {
        this.listCar[0].list = res.content.emissionStandards;
      } else {
        this.listCar[0] = null;
      }
      if (res.content.engineBrands.length != 0) {
        this.listCar[1].list = res.content.engineBrands;
      } else {
        this.listCar[1] = null;
      }
      if (res.content.engineModels.length != 0) {
        this.listCar[2].list = res.content.engineModels;
      } else {
        this.listCar[2] = null;
      }
      if (res.content.horsepower.length != 0) {
        this.listCar[3].list = res.content.horsepower;
      } else {
        this.listCar[3] = null;
      }
      if (res.content.cargoBoxLengths.length != 0) {
        this.listCar[4].list = res.content.cargoBoxLengths; // 货箱长
      } else {
        this.listCar[4] = null;
      }
      if (res.content.outerLengths.length != 0) {
        this.listCar[5].list = res.content.outerLengths; // 车外廓长
      } else {
        this.listCar[5] = null;
      }
      if (res.content.driveTypes.length != 0) {
        this.listCar[6].list = res.content.driveTypes; // 驱动行驶
      } else {
        this.listCar[6] = null;
      }
      if (res.content.speedRatios.length != 0) {
        this.listCar[7].list = res.content.speedRatios; // 速比
      } else {
        this.listCar[7] = null;
      }
      var array = this.listCar;
      for (var i = 0; i < array.length; i++) {
        if (
          array[i] == " " ||
          array[i] == null ||
          typeof array[i] == "undefined"
        ) {
          array.splice(i, 1);
          i = i - 1;
        }
      }
      console.log(array);
      this.listCar = array;
      this.quotationForm();
      this.infoModelShow = true;
    },
    // 点击上面的重置按钮
    resetButton() {
      this.listCar = [
        { title: "排放标准", list: [] },
        { title: "发动机品牌", list: [] },
        { title: "发动机型号", list: [] },
        { title: "马力(Ps)", list: [] },
        { title: "货箱长(mm)", list: [] },
        { title: "车外廓长(mm)", list: [] },
        { title: "驱动形式", list: [] },
        { title: "速比", list: [] },
      ];
      this.tableData = [];
      this.getDate.model = "";
      this.getDate.brand = "";
      this.getDate.category = "";
      this.getDate.type = "";
      this.isActive = null;
      this.isActiveType = null;
      this.infoModelShow = false;
      this.showActiveType = false;
    },
    //进入页面就发起请求 品牌车型
    async RequestData() {
      // return;
      const { data: res } = await this.$http({
        method: "post",
        url: `/api/vehicleOffer/getVehicleBrands`,
        data: {},
      });
      this.getDate.brand = res.content[0].brands[0].value;
      console.log(this.getDate.brand);
      if (res.resultStates == 1) return this.$message.error("暂无报价");
      this.options = res.content;
      this.query(1);
    },
    // 功能类型/车辆类型
    async RequestList() {
      const { data: res } = await this.$http({
        method: "post",
        url: `/api/vehicleOffer/getVehicleCategory`,
        data: {},
      });
      console.log(res.content);
      this.a = res.content;
      this.list = [];
      this.a.forEach((item) => {
        this.list.push({ type: item.category });
      });
    },
    oooo(item) {
      console.log(item);
      let element = document.getElementById(item);
      // element.scrollIntoView({ block: "center", inline: "nearest" });
      element.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "nearest",
      });
    },
    // 点击下面的按钮，点一下 发一下请求
    async quotationForm() {
      if (this.given) {
        if (this.indexListOne == 0) {
          var a = {
            model: this.getDate.model,
          };
        } else {
          var a = {
            brand: this.getDate.brand,
            category: this.getDate.category,
            type: this.getDate.type,
          };
        }
        this.tableData = [];

        const { data: res } = await this.$http({
          method: "post",
          url: `/api/vehicleOffer/getVehicleModelList`,
          data: a,
        });
        if (res.resultStates == 1) {
          this.tableData = [];
          // this.$message.error("暂无报价");
          return;
        }
        if (res.content.items.length == 0) {
          console.log("没数据");
          this.tableData = [];
          this.$message.error("暂无报价");
          this.infoModelShow = false;
          return;
        }
        console.log("!11");

        this.total = res.content.count;
        this.tableData = res.content.items;
      } else {
        console.log("点击下面");
        this.tableData = [];
        let a = Object.assign(this.getDate, this.listBottom, this.pages);
        console.log(a);
        const { data: res } = await this.$http({
          method: "post",
          url: `/api/vehicleOffer/getVehicleModelList`,
          data: a,
        });
        console.log(this.a);
        if (res.resultStates == 1) {
          this.tableData = [];
          this.$message.error("暂无报价");
          return;
        }
        if (res.content.items.length == 0) {
          this.tableData = [];
          this.$message.error("暂无报价");
          this.infoModelShow = false;

          return;
        }
        this.total = res.content.count;
        this.tableData = res.content.items;
      }
      return;
    },

    // 点击报价按钮
    async offer(item) {
      const { data: res } = await this.$http({
        method: "POST",
        url: "/api/vehicleOffer/getConfig",
        data: {},
      });
      this.msgOptions = res.content.areas;
      // return;
      // console.log(item)
      // console.log(item.admCode)
      this.dialogFormVisible = true;
      let msg = {
        recentPriceOffer: item.recentPriceOffer,
        guidePriceOffer: item.guidePriceOffer,
        admCode: item.admCode,
        guidePrice: item.guidePrice,
        recentPrice: item.recentPrice,
        vehicleModelId: item.id,
      };
      // console.log(res.content.areas[0].label)
      // this.QuotationItem.area = res.content.areas[0]
      this.QuotationItem = msg;
    },
    async quotationConfirmation() {
      var numReg = /^(([1-9]{1}\d*)|(0{1}))(\.\d{1,2})?$/;
      var numRe = new RegExp(numReg);
      // 开始
      // 两个为空直接返回
      if (
        this.QuotationList.recentPriceOffer == "" &&
        this.QuotationList.guidePriceOffer == ""
      ) {
        return this.$message.error("输入不能为空");
      }
      let arr = this.msgOptions.filter((item) => {
        if (item.value == this.QuotationItem.area) {
          return item;
        }
      });
      let item = {
        admCode: Number(this.QuotationItem.area),
        area: arr[0].label,
        guidePrice: this.QuotationItem.guidePrice,
        recentPrice: this.QuotationItem.recentPrice,
        vehicleModelId: this.QuotationItem.vehicleModelId,
        guidePriceOffer: this.QuotationList.guidePriceOffer,
        recentPriceOffer: this.QuotationList.recentPriceOffer,
      };
      console.log(this.QuotationItem.admCode);
      // return
      const { data: res } = await this.$http({
        method: "post",
        url: `/api/vehicleOffer/addVehicleOffer`,
        data: item,
      });
      console.log(res);
      // if (res.resultStates == 1) return this.$message.error("暂无报价");
      this.$message.success("成功");
      this.dialogFormVisible = false;
      this.QuotationList.guidePriceOffer = "";
      this.QuotationList.recentPriceOffer = "";
      this.given = false;
      // this.pages.pageNumber = 1;
      // 让报价的数据清空
      this.quotationForm();
    },
    // 分页器
    handleCurrentChange(e) {
      console.log(e);
      this.pages.pageNumber = e;
      this.given = false;
      this.quotationForm();
    },
    handleSizeChange(e) {
      this.pages.pageSize = e;
      this.given = false;
      this.quotationForm();
    },

    async aaa(ite, data, index) {
      this.listCar = [
        { title: "排放标准", list: [], name: "emissionStandard" },
        { title: "发动机品牌", list: [], name: "engineBrand" },
        { title: "发动机型号", list: [], name: "engineModel" },
        { title: "马力(Ps)", list: [], name: "horsepower" },
        { title: "货箱长(mm)", list: [], name: "cargoBoxLength" },
        { title: "车外廓长(mm)", list: [], name: "outerLength" },
        { title: "驱动形式", list: [], name: "driveType" },
        { title: "速比", list: [], name: "speedRatio" },
      ];
      this.listBottom[`${data}`] = ite;
      console.log(this.listBottom);
      this.divActive = true;
      this.$refs.aa[index].doClose();
      this.mouseenterActive = null;
      this.given = false;
      this.pages.pageNumber = 1;

      this.quotationForm();
      let a = {
        model: this.getDate.model,
        brand: this.getDate.brand,
        category: this.getDate.category,
        type: this.getDate.type,
      };
      console.log(a);
      let obj = { ...a, ...this.listBottom };
      // 找自身的调整
      const { data: res } = await this.$http({
        method: "post",
        url: `/api/vehicleOffer/getVehicleFilter`,
        data: obj,
      });
      console.log(res);
      if (res.content.emissionStandards.length != 0) {
        this.listCar[0].list = res.content.emissionStandards;
      } else {
        this.listCar[0] = null;
      }
      if (res.content.engineBrands.length != 0) {
        this.listCar[1].list = res.content.engineBrands;
      } else {
        this.listCar[1] = null;
      }
      if (res.content.engineModels.length != 0) {
        this.listCar[2].list = res.content.engineModels;
      } else {
        this.listCar[2] = null;
      }
      if (res.content.horsepower.length != 0) {
        this.listCar[3].list = res.content.horsepower;
      } else {
        this.listCar[3] = null;
      }
      if (res.content.cargoBoxLengths.length != 0) {
        this.listCar[4].list = res.content.cargoBoxLengths; // 货箱长
      } else {
        this.listCar[4] = null;
      }
      if (res.content.outerLengths.length != 0) {
        this.listCar[5].list = res.content.outerLengths; // 车外廓长
      } else {
        this.listCar[5] = null;
      }
      if (res.content.driveTypes.length != 0) {
        this.listCar[6].list = res.content.driveTypes; // 驱动行驶
      } else {
        this.listCar[6] = null;
      }
      if (res.content.speedRatios.length != 0) {
        this.listCar[7].list = res.content.speedRatios; // 速比
      } else {
        this.listCar[7] = null;
      }
      var array = this.listCar;
      for (var i = 0; i < array.length; i++) {
        if (
          array[i] == " " ||
          array[i] == null ||
          typeof array[i] == "undefined"
        ) {
          array.splice(i, 1);
          i = i - 1;
        }
      }
      this.listCar = array;
      console.log(this.listCar);
    },
    async handleClose(tap) {
      this.listBottom[`${tap}`] = "";
      var qqq = 0;
      for (var b in this.listBottom) {
        if (this.listBottom[b] == "") {
          qqq = qqq + 1;
        }
      }
      if (qqq == 8) {
        this.divActive = false;
      }
      this.given = false;
      this.pages.pageNumber = 1;
      this.quotationForm();
      let a = {
        model: this.getDate.model,
        brand: this.getDate.brand,
        category: this.getDate.category,
        type: this.getDate.type,
      };
      this.listCar = [
        { title: "排放标准", list: [], name: "emissionStandard" },
        { title: "发动机品牌", list: [], name: "engineBrand" },
        { title: "发动机型号", list: [], name: "engineModel" },
        { title: "马力(Ps)", list: [], name: "horsepower" },
        { title: "货箱长(mm)", list: [], name: "cargoBoxLength" },
        { title: "车外廓长(mm)", list: [], name: "outerLength" },
        { title: "驱动形式", list: [], name: "driveType" },
        { title: "速比", list: [], name: "speedRatio" },
      ];
      let obj = { ...a, ...this.listBottom };
      // 找自身的调整
      const { data: res } = await this.$http({
        method: "post",
        url: `/api/vehicleOffer/getVehicleFilter`,
        data: obj,
      });
      if (res.content.emissionStandards.length != 0) {
        this.listCar[0].list = res.content.emissionStandards;
      } else {
        this.listCar[0] = null;
      }
      if (res.content.engineBrands.length != 0) {
        this.listCar[1].list = res.content.engineBrands;
      } else {
        this.listCar[1] = null;
      }
      if (res.content.engineModels.length != 0) {
        this.listCar[2].list = res.content.engineModels;
      } else {
        this.listCar[2] = null;
      }
      if (res.content.horsepower.length != 0) {
        this.listCar[3].list = res.content.horsepower;
      } else {
        this.listCar[3] = null;
      }
      if (res.content.cargoBoxLengths.length != 0) {
        this.listCar[4].list = res.content.cargoBoxLengths; // 货箱长
      } else {
        this.listCar[4] = null;
      }
      if (res.content.outerLengths.length != 0) {
        this.listCar[5].list = res.content.outerLengths; // 车外廓长
      } else {
        this.listCar[5] = null;
      }
      if (res.content.driveTypes.length != 0) {
        this.listCar[6].list = res.content.driveTypes; // 驱动行驶
      } else {
        this.listCar[6] = null;
      }
      if (res.content.speedRatios.length != 0) {
        this.listCar[7].list = res.content.speedRatios; // 速比
      } else {
        this.listCar[7] = null;
      }
      var array = this.listCar;
      for (var i = 0; i < array.length; i++) {
        if (
          array[i] == " " ||
          array[i] == null ||
          typeof array[i] == "undefined"
        ) {
          array.splice(i, 1);
          i = i - 1;
        }
      }
      this.listCar = array;
    },
    mouseenter(id) {
      this.mouseenterActive = id;
    },
    mouseout(id) {
      this.mouseenterActive = null;
    },
  },
  // 以下是生命周期钩子   注：没用到的钩子请自行删除
  /**
   * 在实例初始化之后，组件属性计算之前，如data属性等
   */
  beforeCreate() {},
  /**
   * 组件实例创建完成，属性已绑定，但DOM还未生成，$ el属性还不存在
   */
  created() {},
  /**
   * 在挂载开始之前被调用：相关的 render 函数首次被调用。
   */
  beforeMount() {},
  /**
   * el 被新创建的 vm.$ el 替换，并挂载到实例上去之后调用该钩子。
   * 如果 root 实例挂载了一个文档内元素，当 mounted 被调用时 vm.$ el 也在文档内。
   */
  mounted() {
    //进入页面就会发起请求 品牌车型
    this.RequestData();
    //进入页面就会发起请求 功能类型/车辆类型
    this.RequestList();
    // this.quotationForm();

    //
  },
  activated() {
    console.log("11");
    //进入页面就会发起请求 功能类型/车辆类型
    this.quotationForm();
  },
  beforeRouteLeave(to, from, next) {
    if (to.path != "/pictureImage") {
      var that = this;
      let vnode = that.$vnode;
      let parentVnode = vnode && vnode.parent;
      if (
        parentVnode &&
        parentVnode.componentInstance &&
        parentVnode.componentInstance.cache
      ) {
        var key =
          vnode.key == null
            ? vnode.componentOptions.Ctor.cid +
              (vnode.componentOptions.tag
                ? `::${vnode.componentOptions.tag}`
                : "")
            : vnode.key;
        var cache = parentVnode.componentInstance.cache;
        var keys = parentVnode.componentInstance.keys;

        if (cache[key]) {
          that.$destroy();
          // remove key
          if (keys.length) {
            var index = keys.indexOf(key);
            if (index > -1) {
              keys.splice(index, 1);
            }
          }

          cache[key] = null;
        }
      }
    }

    next();
  },

  // beforeRouteLeave(to, from, next) {
  //   if (to.path == "/pictureImage") {
  //     console.log("试了");
  //     from.meta.keepAlive = true;
  //   } else {
  //     from.meta.keepAlive = false;
  //     console.log("不是");
  //   }
  //   console.log(from);
  //   console.log(to);
  //   next();
  // },
  /**
   * 数据更新时调用，发生在虚拟 DOM 重新渲染和打补丁之前。
   * 你可以在这个钩子中进一步地更改状态，这不会触发附加的重渲染过程。
   */
  beforeUpdate() {},
  /**
   * 由于数据更改导致的虚拟 DOM 重新渲染和打补丁，在这之后会调用该钩子。
   * 当这个钩子被调用时，组件 DOM 已经更新，所以你现在可以执行依赖于 DOM 的操作。
   */
  updated() {},
  /**
   * keep-alive 组件激活时调用。 仅针对keep-alive 组件有效
   */
  /**
   * keep-alive 组件停用时调用。 仅针对keep-alive 组件有效

  /**
   * 实例销毁之前调用。在这一步，实例仍然完全可用。
   */
  beforeDestroy() {
    // this.$destroy(true);
  },
  deactivated() {
    // Object.assign(this.$data, this.$options.data())
    //清除keep-alive的缓存
    // this.$destroy(true);
  },
  /**
   * Vue 实例销毁后调用。调用后，Vue 实例指示的所有东西都会解绑定，
   * 所有的事件监听器会被移除，所有的子实例也会被销毁。
   */
  destroyed() {},
};
</script>

<style scoped lang='less'>
.widthCommit {
  width: 1000px;
  margin: 0 auto;
}
// 动态样式
.active {
  color: #1883e3 !important;
}
/* 顶部开始 */
.reportTitli {
  font-size: 20px;
  font-family: PingFang SC;
  font-weight: bold;
  color: #333333;
  opacity: 1;
}
/* 顶部结束 */
.getVehicleTitle {
  font-size: 20px;
  font-family: PingFang SC;
  font-weight: bold;
  color: #333333;
  opacity: 1;
}
/* 车型报价modelQuotation开始 */
.modelQuotation {
  height: 300px;
  background: #ffffff;
  box-shadow: 0px 0px 10px #bfdff7;
  opacity: 1;
  border-radius: 10px;
  box-sizing: border-box;
  padding: 24px 30px 0 64px;
}

/deep/ .el-form-item__label {
  font-size: 16px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #666666;
  opacity: 1;
  margin-right: 20px;
}

/deep/ .botton {
  margin-left: 40px;
}
/deep/.inp {
  width: 106%;
}
.functionType {
  cursor: pointer;
  float: left;
  margin-left: 20px;
  font-size: 16px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #333333;
}
/deep/.functionDiv {
  margin-bottom: 10px;
}
.functionBtn {
  width: 100%;
  height: 36px;
}
.functionBtn > div {
  float: right;
}
/deep/.funBotton {
  width: 116px;
  margin-right: 30px;
}
/deep/.funBottonOne {
  width: 116px;
  margin-right: 0;
}
/deep/.funBottonOneOne {
  width: 70px;
  margin-left: 15px;
}

/* 车型报价modelQuotation结束 */
// 车辆信息型号infoModel开始
.infoModel {
  background: #ffffff;
  box-shadow: 0px 0px 10px #bfdff7;
  opacity: 1;
  border-radius: 10px;
  margin-top: 20px;
  box-sizing: border-box;
  padding: 20px;
}
.infoModelTitle {
  display: flex;
  align-items: center;
  background: #ecf3fd;
  height: 66px;
  position: relative;
}
.infoModelTextTitle {
  width: 104px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #333333;
  opacity: 1;
  height: 66px;
  font-size: 16px;
  text-align: center;
  // line-height: 66px;
  padding-top: 21px;
  box-sizing: border-box;
  cursor: pointer;
}
// 车辆信息型号infoModel结束
// 车辆配置信息configurationInfo开始
.configurationInfo {
  margin-top: 40px;
}
.tableTitle {
  font-size: 16px;
  font-family: PingFang SC;
  font-weight: 500;
  line-height: 22px;
  color: #333333;
  opacity: 1;
}
.tableDiv {
  width: 100%;
  height: 25px;
  display: flex;
  justify-content: space-between;
}
.tableLi {
  //   width: 70px;
  height: 20px;
  border: 1px solid #999999;
  opacity: 1;
  border-radius: 4px;
  font-size: 10px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #333333;
  line-height: 20px;
}
.referencePrice {
  position: relative;
  height: 50px;
  box-sizing: border-box;
  padding-top: 14px;
}
.referencePriceMian {
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: bold;
  color: #333333;
  opacity: 1;
}
.referencePriceMianTitle {
  position: absolute;
  right: 154px;
  top: 15px;
  width: 65px;
  height: 20px;
  // background: #c7e0f9;
  border-radius: 16px 16px 16px 0px;
  font-size: 12px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #666666;
}
.referencePriceMianTitleOne {
  right: 153px;
}
/deep/.el-button--text {
  font-weight: bold;
}
// 车辆配置信息configurationInfo结束
.sctionDiv {
  display: flex;
}
.sctionDivTit {
  width: 30px;
  position: absolute;
  z-index: 9999;
  background: #f4f7fe;
  height: 100%;
}
.sctionDivTit p {
  text-align: center;
  font-size: 7px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #666666;
  height: 9px;
  opacity: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.sctionTow {
  flex: 1;
  position: relative;
}
/deep/.el-select-group__title {
  padding: 0 !important;
  background: #f4f7fe;
  font-size: 8px;
  font-family: PingFang SC;
  font-weight: bold;
  color: #1883e3;
  opacity: 1;
}
/deep/ .el-dialog__header {
  background: #1883e3;
  text-align: center;
}
/deep/ .el-dialog__header .el-dialog__title {
  color: #ffffff;
}
/deep/ .form {
  box-sizing: border-box;
  padding: 0 100px 0 100px;
}
/deep/ .inputLi {
  width: 248px;
  background: #ecf3fd;
  opacity: 1;
  border-radius: 4px;
}
/deep/ .formDivA .el-form-item__label {
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: bold;
  color: #333333;
  opacity: 1;
}
/deep/ .inputLi > .el-input__inner {
  width: 248px;
  background: #ecf3fd;
  opacity: 1;
  border-radius: 4px;
}
.offerText {
  font-size: 16px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #db1818;
}
.mouseenterActiveClass {
  font-size: 16px;
  font-family: PingFang SC;
  background: #bfdff7;
  color: #333333;
  font-weight: 600;
}
.infoModelMianList {
  display: flex;
  flex-wrap: wrap;
}
.infoModelMianListOne {
  width: 14%;
  height: 40px;
  line-height: 40px;
  text-align: center;
}
.tableNewMian {
  width: 100%;
  height: 55px;
  box-sizing: border-box;
  padding-top: 5px;
  display: flex;
  justify-content: space-between;
}
.tableNewMianOne {
  width: 200px;
  display: flex;
  flex-direction: column;
  align-items: left;
  font-size: 12px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #666666;
  opacity: 1;
}
/deep/ .el-input__inner {
  background: #ecf3fd;
}

</style>
<style>
.el-scrollbar__view .el-select-group__wrap:not(:last-of-type)::after {
  background: none !important;
}
.el-select-group__wrap:not(:last-of-type) {
  padding-bottom: 0 !important;
}
.el-select-group__title {
  text-align: center !important;
}
.el-cascader-menu__wrap {
  height: 400px;
}
.el-select-dropdown__wrap {
  max-height: 411px !important;
}
.imagee {
  cursor: pointer;
  width: 80px;
  height: 60px;
  background: url("https://vehicle-library.oss-cn-beijing.aliyuncs.com/cvbd-icon/staff_vehicle_offer_background_20210701.png");
  background-size: 100% 100%;
  /* background: #000; */
}
.img {
  width: 80px;
  height: 60px;
  position: relative;
}
.classText {
  width: 50px;
  height: 60px;
  position: absolute;
  top: 62px;
  left: 26px;
  font-size: 10px;
  font-family: PingFang SC;
  font-weight: 500;
  line-height: 14px;
  color: #ffffff;
  opacity: 1;
}
.spanText {
  width: 50px;
  height: 15px;
  display: block;
  text-align: center;
}
/*  */
</style>
